import React, {FC, ReactChild, useMemo} from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import {HobbiesDetailPropsType} from './type';
import { PageLayout } from '../PageLayout';
import { Container } from '../Container';
import { Root, TwoColumnRoot, Left, Right, ImageGalleryImageWrapper, ImageGalleryImage } from './style';
import { HobbiesDetailSide } from './HobbiesDetailSide';
import { Link } from '../Link';
import {PageOffset} from "../PageOffset";
import {Typography} from "../Typography";
import {ItemList} from "../ItemList";
import {HobbiesEntry} from "../../type";
import {NarrowContainer} from "../NarrowContainer";
import {MDXComponents} from "../Providers/MDXComponents";
import {ImageViewer} from "../ImageViewer/ImageViewer";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import {useHobbiesDetail} from "./hooks/useHobbiesDetail";
import {SkillsV2} from "../SkillsV2";
import {HOBBIES_LIST} from "../../pathTemplates";
import {RoundButton} from "../RoundButton";

/**
 * This component is for wrapping up pages that lay in the content/hobbies/ folder.
 * See gatsby-node.js for details.
 */
export const HobbiesDetail: FC<HobbiesDetailPropsType> = ({
    data,
    path,
}) => {
    const { getImageWrapperProps, getImageProps } = useHobbiesDetail();

    const { mdx } = data ?? {};
    const frontmatter = mdx?.frontmatter as HobbiesEntry;
    const title = frontmatter?.title ?? "";

    const location = useMemo(() => ({ pathname: path }), [path]);

    let year = 0;
    const dateSplit = (frontmatter?.date ?? "").split("-");
    if (dateSplit.length === 3) {
        year = parseInt(dateSplit[0])
    }

    const learned = useMemo(() => {
        return frontmatter?.learned?.map(item => ({
            text: item,
        })) ?? [];
    }, [frontmatter]);
    const tutorials = frontmatter?.tutorials ?? [];

    const software = frontmatter?.software ?? [];
    const skills = frontmatter?.skills ?? [];

    return (
        <PageLayout pageContext={mdx} location={location}>
            <Root>
                <TwoColumnRoot container spacing={6}>
                    <Left item md={4} sm={12}>
                        <HobbiesDetailSide data={mdx} />
                    </Left>
                    <Right item md={8} sm={12}>
                        <Container>
                            <Typography
                                variant="h1"
                                component="h1"
                                enableVerticalGutter
                            >
                                {title}
                            </Typography>
                        </Container>
                        {
                            (year !== 0)
                            &&
                            <NarrowContainer>
                                <Typography variant="h4" component="span">
                                    Year: {year}
                                </Typography>
                            </NarrowContainer>
                        }
                        {
                            skills.length > 0
                            &&
                            <NarrowContainer>
                                <SkillsV2 list={skills.map(skill => skill.code).join(",")} marginTop="1.5rem" />
                            </NarrowContainer>
                        }
                        <div>
                            <MDXProvider components={MDXComponents}>
                                <MDXRenderer pageContext={mdx}>{mdx.body}</MDXRenderer>
                            </MDXProvider>
                            {
                                learned.length > 0
                                &&
                                <NarrowContainer>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        enableVerticalGutter
                                    >
                                        Learned this time
                                    </Typography>
                                    <ItemList
                                        items={learned}
                                    />
                                </NarrowContainer>
                            }
                            {
                                tutorials.length > 0
                                &&
                                <NarrowContainer>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        enableVerticalGutter
                                    >
                                        Tutorials used
                                    </Typography>
                                    <ItemList
                                        markerEmoji="👉"
                                        items={tutorials}
                                    />
                                </NarrowContainer>
                            }
                            <NarrowContainer>
                                <Typography
                                    variant="h2"
                                    component="h2"
                                    enableVerticalGutter
                                >
                                    Images
                                </Typography>
                            </NarrowContainer>
                            <ImageViewer>
                                <NarrowContainer>
                                    <Grid container spacing={5}>
                                        {frontmatter.images.filter(image => !image.isCover).map((image, index) => {
                                            return (
                                                <Grid md={6} sm={6} xs={12} key={index} component="div">
                                                    <ImageGalleryImageWrapper
                                                        {...getImageWrapperProps(image)}
                                                    >
                                                        <ImageGalleryImage {...getImageProps(image)} />
                                                    </ImageGalleryImageWrapper>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </NarrowContainer>
                            </ImageViewer>
                            <NarrowContainer>
                                <Box marginTop={8} display="flex" justifyContent="center" width="100%">
                                    <Link
                                        to={HOBBIES_LIST}
                                    >
                                        <RoundButton>
                                            Back to list
                                        </RoundButton>
                                    </Link>
                                </Box>
                            </NarrowContainer>
                            <PageOffset />
                        </div>
                    </Right>
                </TwoColumnRoot>
            </Root>
        </PageLayout>
    );
};

export const HobbiesDetailQuery = graphql`
    query HobbiesDetailQuery($id: String) {
        mdx(id: { eq: $id }) {
            id
            body
            frontmatter {
                title
                keywords
                description
                displayPageTitle
                published
                date
                updatedAt
                learned
                tutorials {
                    url
                    text
                }
                skills {
                    code
                    version
                }
                images {
                    isCover
                    galleryId
                    author
                    source
                    sourceText
                    title
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                width: 3000
                                quality: 80
                                placeholder: BLURRED
                            )
                        }
                    }
                }
            }
        }
    }
`;

export default HobbiesDetail;
